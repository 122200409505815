import { AxiosResponse } from 'axios';

import {
  CommonResponseType,
  ConfirmationCodeType,
  CreatePasswordType,
  LoginRequestType,
  LoginResponseType,
  SignUpRequestType,
  UserType,
} from './types';

import { authServiceClient } from 'config';

export const logInRequest = (data: Omit<LoginRequestType, 'checkResponse'>) => {
  return authServiceClient.post<
    LoginResponseType,
    AxiosResponse<LoginResponseType>,
    Omit<LoginRequestType, 'checkResponse'>
  >(`/auth/login`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const signUpRequest = (data: Omit<SignUpRequestType, 'checkResponse'>) => {
  return authServiceClient.post<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    Omit<SignUpRequestType, 'checkResponse'>
  >(`/auth/verify-email`, data);
};

export const restorePasswordRequest = (
  data: Omit<SignUpRequestType, 'checkResponse'>,
) => {
  return authServiceClient.post<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    Omit<SignUpRequestType, 'checkResponse'>
  >(`/auth/forgot-password`, data);
};

export const confirmationCodeRequest = (
  data: Omit<ConfirmationCodeType, 'checkResponse'>,
) => {
  return authServiceClient.post<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    Omit<ConfirmationCodeType, 'checkResponse'>
  >(`/auth/verify-code`, data);
};

export const createPasswordRequest = ({
  password,
  confirmPassword,
  email,
  isSignUp,
}: Omit<CreatePasswordType, 'checkResponse'> & { email: string }) => {
  const data = {
    password,
    confirmPassword,
    email,
  };

  return authServiceClient.post<
    LoginResponseType,
    AxiosResponse<LoginResponseType>,
    Omit<CreatePasswordType, 'checkResponse' | 'isSignUp'> & { email: string }
  >(`/auth/${isSignUp ? 'registration' : 'reset-password'}`, data);
};

export const getMeRequest = () => {
  return authServiceClient.get<UserType, AxiosResponse<UserType>>(`/user/me`);
};

export const logOutRequest = () => {
  return authServiceClient.get<CommonResponseType, AxiosResponse<CommonResponseType>>(
    `/auth/logout`,
  );
};

export const refreshTokenRequest = (token: string) => {
  return authServiceClient.get<
    { accessToken: string; refreshToken: string },
    AxiosResponse<{ accessToken: string; refreshToken: string }>
  >(`/auth/refresh`, { headers: { Authorization: `Bearer ${token}` } });
};
