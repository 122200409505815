import { jwtDecode } from 'jwt-decode';

import {
  confirmationCodeRequest,
  ConfirmationCodeType,
  createPasswordRequest,
  CreatePasswordType,
  getMeRequest,
  logInRequest,
  LoginRequestType,
  logOutRequest,
  restorePasswordRequest,
  signUpRequest,
  SignUpRequestType,
} from 'api';
import { LsKeys, Roles } from 'enums';
import { authActions } from 'state';
import { createAppAsyncThunk } from 'utils';

const me = createAppAsyncThunk<any, { checkResponse: (role: Roles) => void }>(
  'me',
  async ({ checkResponse }, { dispatch }) => {
    try {
      dispatch(authActions.setLoading({ isLoading: true }));

      const res = await getMeRequest();

      if (res?.data?.role) {
        checkResponse(res.data.role);
      }

      dispatch(authActions.setUserInfo(res.data));

      return res;
    } finally {
      dispatch(authActions.setInitialized());
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

const login = createAppAsyncThunk<any, LoginRequestType>(
  'auth/login-page',
  async ({ checkResponse, ...args }, { dispatch }) => {
    try {
      dispatch(authActions.setLoading({ isLoading: true }));
      const res = await logInRequest(args);
      const user: { role: Roles } = jwtDecode(res?.data?.tokens?.accessToken);

      dispatch(
        authActions.setToken({
          accessToken: res?.data?.tokens?.accessToken,
        }),
      );
      localStorage.setItem(
        LsKeys.TOKEN_INFO,
        JSON.stringify({ refreshToken: res?.data?.tokens?.refreshToken }),
      );
      checkResponse(user?.role, res?.status);
      dispatch(authActions.setUserRole({ role: user?.role }));
    } finally {
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

const singUp = createAppAsyncThunk<any, SignUpRequestType>(
  'auth/sign-up',
  async ({ email, checkResponse }, { dispatch }) => {
    try {
      dispatch(authActions.setLoading({ isLoading: true }));

      const res = await signUpRequest({ email, isVeb: true });

      dispatch(authActions.setUserEmail({ email: email.trim() }));
      checkResponse(res?.status);
    } finally {
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

const logOut = createAppAsyncThunk<any, { checkResponse?: (status: number) => void }>(
  'auth/log-out',
  async ({ checkResponse }, { dispatch }) => {
    try {
      dispatch(authActions.setLoading({ isLoading: true }));
      if (checkResponse) {
        const res = await logOutRequest();

        checkResponse(res?.status);
      }

      dispatch({ type: 'logout/LOGOUT' });
      localStorage.removeItem(LsKeys.TOKEN_INFO);
    } finally {
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

const restorePassword = createAppAsyncThunk<any, SignUpRequestType>(
  'auth/restore-password',
  async ({ email, checkResponse }, { dispatch }) => {
    try {
      dispatch(authActions.setLoading({ isLoading: true }));
      const res = await restorePasswordRequest({ email });

      checkResponse(res?.status);
    } finally {
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

const confirmationCode = createAppAsyncThunk<any, Omit<ConfirmationCodeType, 'email'>>(
  'auth/confirmation-code',
  async ({ checkResponse, ...arg }, { dispatch, getState }) => {
    const { email } = getState().auth.userInfo;

    try {
      dispatch(authActions.setLoading({ isLoading: true }));

      const res = await confirmationCodeRequest({ ...arg, email });

      checkResponse(res?.status);
    } finally {
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

const createPassword = createAppAsyncThunk<any, CreatePasswordType>(
  'auth/create-password',
  async ({ checkResponse, ...arg }, { dispatch, getState }) => {
    const { email } = getState().auth.userInfo;

    try {
      dispatch(authActions.setLoading({ isLoading: true }));

      const res = await createPasswordRequest({ ...arg, email });

      checkResponse(res?.status);
    } finally {
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

export const authThunk = {
  me,
  login,
  singUp,
  logOut,
  restorePassword,
  confirmationCode,
  createPassword,
};
